import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HamburgerICon from '../../assets/images/menu.svg';
import './layout.css';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

function Header() {
    const [name, setName] = useState("")
    const navigate = useNavigate()

    const userLogout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("tokens");
        window.location.reload()
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setName(JSON.parse(localStorage.getItem("user"))?.preferred_username || "")
    }, [])


    return (
        <nav data-v-18ca20c2="" className="navbar navbar-expand top-header">
            <Link className="sidebar-toggle js-sidebar-toggle">
                <img src={HamburgerICon} alt="iClipCoupons" />
            </Link>
            <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align">
                    <li className="nav-item dropdown username-dropdown">
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <Typography >{name}</Typography>

                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar sx={{ width: 32, height: 32 }}></Avatar>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </li>
                </ul>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => navigate("/profile/login")} >
                        <Avatar /> Profile
                    </MenuItem>
                    <MenuItem onClick={userLogout} >
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </div>
        </nav>

    );
}

export default Header;