import { useState, useRef, useEffect } from "react";
import "./DeleteModal.css";
import Modal from "react-bootstrap/Modal";
import Button from "@mui/material/Button";

export const DeleteModal = ({ show, onHide, header, body, confirmDelete }) => {
  const handleDelete = () => {
    confirmDelete();
    onHide();
    return;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{
          height: "70px",
          width: "100%",
        }}
      >
        <h3>{header}</h3>
      </Modal.Header>
      <Modal.Body>
        <h4>{body}</h4>
      </Modal.Body>
      <Modal.Footer
        style={{
          height: "70px",
          width: "100%",
        }}
      >
        <div className="deleteBtnContainer">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="error"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
