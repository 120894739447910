// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itemContainer {
  margin-top: 30px;
  /* border: 1px solid #ccc; */
  /* border-radius: 10px; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  flex-wrap: wrap;
}
.items {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.items:hover {
  background-color: rgb(239, 239, 239);
}
`, "",{"version":3,"sources":["webpack://./src/components/categoryItems/CategoryItems.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,4BAA4B;EAC5B,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,SAAS;EACT,YAAY;EACZ,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".itemContainer {\n  margin-top: 30px;\n  /* border: 1px solid #ccc; */\n  /* border-radius: 10px; */\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  gap: 10px;\n  padding: 5px;\n  flex-wrap: wrap;\n}\n.items {\n  width: 100%;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  padding-right: 10px;\n  padding-left: 10px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n\n.items:hover {\n  background-color: rgb(239, 239, 239);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
