// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** Font Family **/

body {
    font-family: 'Roboto', sans-serif;
    padding: 0px;
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333;
}
*,
::after,
::before {
    box-sizing: border-box;
}
:focus-visible {
    outline: unset;
}
input::placeholder {
    color: #999 !important;
  }
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #999 !important;
}
input::-moz-placeholder { /* Firefox 19+ */
    color: #999 !important;
}
input:-ms-input-placeholder { /* IE 10+ */
    color: #999 !important;
}
input:-moz-placeholder { /* Firefox 18- */
    color: #999 !important;
}
.page-wrapper {
    background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAGlB;IACI,iCAAiC;IACjC,YAAY;IACZ,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;AACf;AACA;;;IAGI,sBAAsB;AAC1B;AACA;IACI,cAAc;AAClB;AACA;IACI,sBAAsB;EACxB;AACF,mCAAmC,wBAAwB;IACvD,sBAAsB;AAC1B;AACA,0BAA0B,gBAAgB;IACtC,sBAAsB;AAC1B;AACA,8BAA8B,WAAW;IACrC,sBAAsB;AAC1B;AACA,yBAAyB,gBAAgB;IACrC,sBAAsB;AAC1B;AACA;IACI,gBAAgB;AACpB","sourcesContent":["/** Font Family **/\n@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n\nbody {\n    font-family: 'Roboto', sans-serif;\n    padding: 0px;\n    margin: 0px;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    color: #333;\n}\n*,\n::after,\n::before {\n    box-sizing: border-box;\n}\n:focus-visible {\n    outline: unset;\n}\ninput::placeholder {\n    color: #999 !important;\n  }\ninput::-webkit-input-placeholder { /* Chrome/Opera/Safari */\n    color: #999 !important;\n}\ninput::-moz-placeholder { /* Firefox 19+ */\n    color: #999 !important;\n}\ninput:-ms-input-placeholder { /* IE 10+ */\n    color: #999 !important;\n}\ninput:-moz-placeholder { /* Firefox 18- */\n    color: #999 !important;\n}\n.page-wrapper {\n    background: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
