import React, { useState, useEffect } from "react";
import "./companyPrefixes.css";
import MainWrapper from "../../components/layout/layout";
import { Navigate, useLoaderData } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from "axios";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from 'react-bootstrap/Spinner';
import { PrefixItems } from "../../components/prefixItems/PrefixItems";
import { addCompanyPrefix, updateCompanyPrefix } from "../../services/companyPrefix";

function CompanyPrefixes() {
  const loader = JSON.parse(useLoaderData())
  const [fieldType, setFieldType] = useState("");
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false);
  const [categoryAddedFlag, setCategoryAddedFlag] = useState(false);
  const [updateId, setUpdateId] = useState(null);

  const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (data.prefix.length < 6 || data.prefix.length > 12) {
      setFieldType("prefix")
      return;
    }
    setFieldType("")

    setLoading(true)

    // update prefix
    if (updateId !== null) {

      console.log("update data: ", updateId, data)
      const res = await updateCompanyPrefix(data, updateId);

      if (res === null) {
        setLoading(false);
        alert("something went wrong, please try later");
        return;
      }

      setCategoryAddedFlag(!categoryAddedFlag);
      setData({});
      setLoading(false);
      setUpdateId(null);
      return;
    }

    // add prefix
    const res = await addCompanyPrefix(data)
    if (res === null) {
      setLoading(false);
      return;
    }
    setCategoryAddedFlag(!categoryAddedFlag)
    setData({})
    setLoading(false);
    setUpdateId(null);
    return;
  }

  const handleUpdate = (data) => {
    setData({});
    setUpdateId(data.id);
    setData({ name: data.company_name, prefix: data.gs1_company_prefix });
  };


  if (loader === null) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/login/companyprefixes" replace={true} />
      </div>
    )
  }

  if (loader.email !== process.env.REACT_APP_ADMIN_EMAIL) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/barcode/login" replace={true} />
      </div>
    )
  }

  return (
    <MainWrapper>
      <div className="content-container">
        <Grid container>
          <Grid item xs={12} md={6} >
            <Typography sx={{ ml: 5 }} component="h1" variant="h5">
              <strong>Add Company Prefix</strong>
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ ml: 5, mt: 5 }} >
              <Grid container>
                <Grid item xs={12} md={8}>
                  <TextField id="name" name="name" type="text" label="Company Name" variant="outlined" required fullWidth
                    value={data.name || ""}
                    onChange={handleChange}
                  />
                  <TextField sx={{ mt: 3 }} id="prefix" name="prefix" type="number" label="Company Prefix" variant="outlined" required fullWidth
                    value={data.prefix || ""}
                    onChange={handleChange}
                    placeholder="Prefix should be 6 to 12 digits"
                    error={fieldType === "prefix"}
                    helperText={fieldType === "prefix" && "Prefix should be 6 to 12 digits"}
                  />
                  <Button className="customBtn" sx={{ mt: 3 }} size="large" type="submit" fullWidth variant="contained">
                    {!loading ? `${updateId ? "Update" : "Add"}` : "Loading..."}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <PrefixItems categoryAddedFlag={categoryAddedFlag}
              handleUpdate={handleUpdate} />
          </Grid>
        </Grid>
      </div>
    </MainWrapper>
  );
}

export default CompanyPrefixes;
