import { useEffect, useState } from "react";
import "./CategoryItems.css";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {
  deleteProductCategory,
  getProductCategory,
} from "../../services/productCategory";
import { DeleteModal } from "../modal/DeleteModal";

export const CategoryItems = ({ categoryAddedFlag, handleUpdate }) => {
  const [categories, setCategories] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  console.log("categoryAddedFlag: ", categoryAddedFlag);

  const fetchProductCategories = async () => {
    const data = await getProductCategory();
    if (data === null) {
      alert("something went wrong, please try later");
      return;
    }
    setCategories(data);
    return;
  };

  useEffect(() => {
    fetchProductCategories();
  }, [categoryAddedFlag]);

  console.log("categoryItems: ", categories);

  const handleDelete = async (id) => {
    setDeleteId(id);
    setModalShow(true);
    return;
  };
  const confirmCategoryDelete = async () => {
    const res = await deleteProductCategory(deleteId);
    if (res === null) {
      alert("something went wrong, please try later");
      setDeleteId(null);
      return;
    }

    setCategories((data) =>
      data.filter((category) => category.product_category_id !== deleteId)
    );
    setDeleteId(null);
    return;
  };

  console.log("deleteId: ", deleteId);

  return (
    <div>
      <Typography component="h1" variant="h5">
        <strong>Categories</strong>
      </Typography>
      <div className="itemContainer">
        {categories?.map((category) => {
          return (
            <div className="items" key={category.product_category_id}>
              <div className="itemName">{category.product_category_name}</div>
              <Stack direction="row">
                <IconButton
                  onClick={() => handleUpdate(category)}
                  aria-label="edit"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(category.product_category_id)}
                  aria-label="delete"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Stack>
            </div>
          );
        })}
      </div>

      {/* delete modal */}
      <DeleteModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        header="Delete Category"
        body="Delete category? This can't be undo."
        confirmDelete={confirmCategoryDelete}
      />
    </div>
  );
};
