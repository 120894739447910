import React, { memo } from "react";
import './layout.css';
import Sidebar from "./sidebar";
import Header from "./header";

const MainWrapper = memo(({ children }) => {

    return (
        <div className="page-wrapper">
            <Sidebar />
            <div className="main">
                <Header />
                {children}
            </div>
        </div>
    );
})

export default MainWrapper;