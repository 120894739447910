// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hr {
  margin: 0;
  padding: 0;
}

.tabs {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
  margin-right: 40px;
  margin-left: 40px;
}
.tab {
  border-top: 1px solid #ccc;
  padding: 10px;
}

.loaderParent {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.couponsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/coupons/Coupons.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,0BAA0B;EAC1B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,SAAS;AACX","sourcesContent":[".hr {\n  margin: 0;\n  padding: 0;\n}\n\n.tabs {\n  border: 1px solid #ccc;\n  border-radius: 10px;\n  padding: 10px;\n  margin-top: 15px;\n  margin-right: 40px;\n  margin-left: 40px;\n}\n.tab {\n  border-top: 1px solid #ccc;\n  padding: 10px;\n}\n\n.loaderParent {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.couponsContainer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
