import React from "react";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const Manufacturer = ({ data, handleManufacturerDelete }) => {
  const navigate = useNavigate();
  const { user_email, user_name } = data;

  const handleNavigation = () => {
    navigate(`/manufacturer-coupon/${user_email}`);
    return;
  };
  return (
    <Card sx={{ maxWidth: 450 }}>
      <CardHeader
        sx={{ mt: 0.5 }}
        avatar={
          <Avatar sx={{ bgcolor: "gray" }} aria-label="recipe">
            {user_name.slice(0, 1).toUpperCase()}
          </Avatar>
        }
        action={
          <IconButton
            aria-label="settings"
            onClick={() => handleManufacturerDelete(user_email)}
          >
            <DeleteIcon />
          </IconButton>
        }
        title={user_name}
        subheader={user_email}
      />
      <CardActions sx={{ mt: 1 }}>
        <Button
          className="customBtn"
          variant="contained"
          size="small"
          onClick={handleNavigation}
        >
          Show coupons
        </Button>
      </CardActions>
    </Card>
  );
};
