import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { CardItem } from "../card/Card";
import { deleteImages, getImages } from "../../services/image";
import Skeleton from "@mui/material/Skeleton";
import { DeleteModal } from "../modal/DeleteModal";

export const Barcodes = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    return async () => {
      setLoading(true);
      const data = await getImages("Barcode");
      if (data === null) {
        setCoupons([]);
        setLoading(false);
        return;
      }
      setCoupons(data);
      setLoading(false);
    };
  }, []);

  const deleteCoupon = (data) => {
    setData(data);
    setModalShow(true);
    return;
  };

  const confirmDeleteCoupon = async () => {
    console.log("confirmDeleteCoupon: ", data);

    const res = await deleteImages("Barcode", data.name);

    if (res === null) {
      alert("Something went wrong, please try later.");
      return;
    }

    const newData = coupons.filter((item) => item.name !== data.name);
    setCoupons(newData);
    return;
  };

  if (loading) {
    return (
      <Grid item xs={6} md={4} lg={3}>
        <Skeleton variant="rectangular" width={230} height={200} />
      </Grid>
    );
  }

  return (
    <Grid container spacing={1}>
      {coupons?.length !== 0 ? (
        coupons?.map((items, key) => {
          return (
            <CardItem data={items} key={key} deleteCoupon={deleteCoupon} />
          );
        })
      ) : (
        <Grid item xs={6} md={4} lg={3}>
          <h4>Barcode is not available</h4>
        </Grid>
      )}

      {/* delete modal */}
      <DeleteModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        header="Delete Barcode"
        body="Delete Barcode? This can't be undo."
        confirmDelete={confirmDeleteCoupon}
      />
    </Grid>
  );
};
