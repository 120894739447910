import React from "react";
import "./Card.css";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CardActions from "@mui/material/CardActions";

export const CardItem = ({ data, deleteCoupon }) => {
  const { url, name, date } = data;

  const handleDelete = () => {
    deleteCoupon(data);
  };

  return (
    <Grid item xs={6} md={4} lg={3}>
      <Card>
        <CardMedia
          className="cardImage"
          // height="200px"
          component="img"
          image={url}
          alt="Coupon"
        />
        <CardHeader title={name} subheader={`Generated at: ${date}`} />
        <CardActions>
          <IconButton onClick={handleDelete} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
};
