import React, { useState, useEffect } from "react";
import "./Manufacturers.css";
import MainWrapper from "../../components/layout/layout";
import { Navigate, useLoaderData } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Spinner from "react-bootstrap/Spinner";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Skeleton from "@mui/material/Skeleton";
import { Manufacturer } from "../../components/manufacturer/Manufacturer";
import {
  deleteManufacturer,
  getManufacturers,
} from "../../services/manufacturers";
import { DeleteModal } from "../../components/modal/DeleteModal";

export const Manufacturers = () => {
  const loader = JSON.parse(useLoaderData());
  const [manufacturers, setManufacturers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [resType, setResType] = useState("success");
  const [resMessage, setResMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [manufacturerEmail, setManufacturerEmail] = useState("");

  useEffect(() => {
    return async () => {
      setLoading(true);
      const res = await getManufacturers();

      if (res === null) {
        setResType("error");
        setResMessage("Some error occurred, Please try later.");
        setOpen(true);
        setLoading(false);
        return;
      }

      setManufacturers(
        res.filter(
          (manufacturer) =>
            manufacturer.user_email !== process.env.REACT_APP_ADMIN_EMAIL
        )
      );
      setLoading(false);
      return;
    };
  }, []);

  const handleManufacturerDelete = async (email) => {
    setManufacturerEmail(email);

    setModalShow(true);
    return;
  };

  const confirmManufacturerDelete = async () => {
    console.log("delete confirmation: ", manufacturerEmail);

    const res = await deleteManufacturer(manufacturerEmail);

    if (res === null) {
      setResType("error");
      setResMessage("Some error occurred, Please try later.");
      setOpen(true);
      return;
    }

    setManufacturers((manufacturers) =>
      manufacturers.filter(
        (manufacturer) => manufacturer.user_email !== manufacturerEmail
      )
    );
    setResType("success");
    setResMessage("manufacturer deleted successfully");
    setOpen(true);
    return;
  };

  if (loader === null) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/login/manufacturers" replace={true} />
      </div>
    );
  }

  if (loader.email !== process.env.REACT_APP_ADMIN_EMAIL) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/barcode/login" replace={true} />
      </div>
    );
  }

  return (
    <MainWrapper>
      <div className="content-container">
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography sx={{ mx: 5 }} component="h1" variant="h5">
              <strong>Manufacturers</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Collapse in={open}>
              <Alert
                severity={resType}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {resMessage}
              </Alert>
            </Collapse>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            sx={{
              mx: 5,
              mt: 5,
              display: "flex",
              flexDirection: "row",
              gap: 2,
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            xs={12}
            md={12}
          >
            {loading ? (
              <Skeleton variant="rounded" width={280} height={150} />
            ) : manufacturers.length === 0 ? (
              <Typography component="h1" variant="h5">
                <strong>Manufacturers not found</strong>
              </Typography>
            ) : (
              manufacturers?.map((manufacturer, id) => {
                return (
                  <div key={id}>
                    <Manufacturer
                      data={manufacturer}
                      handleManufacturerDelete={handleManufacturerDelete}
                    />
                  </div>
                );
              })
            )}
          </Grid>
        </Grid>

        {/* delete modal */}
        <DeleteModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          header="Delete Manufacturer"
          body="Delete manufacturer? This can't be undo."
          confirmDelete={confirmManufacturerDelete}
        />
      </div>
    </MainWrapper>
  );
};
