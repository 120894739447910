import React, { useState, useEffect } from "react";
import "./Profile.css";
import MainWrapper from "../../components/layout/layout";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Spinner from "react-bootstrap/Spinner";
import { UpdateUser } from "../../components/profile/updateUser/UpdateUser";
import { getUser } from "../../services/user";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Profile = () => {
  const loader = useLoaderData();
  const user = JSON.parse(loader);
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [userData, setUserData] = useState(null);

  const getUserData = async () => {
    const res = await getUser(user?.email);

    if (res === null) {
      setUserData(null);
      toast.error("Unable to get user details, please try later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setUserData(res);
    return;
  };

  useEffect(() => {
    return () => {
      getUserData();
    };
  }, []);

  const userUpdated = async () => {
    getUserData();
    return;
  };

  const userLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("tokens");
    window.location.reload();
  };

  if (loader === null) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/login/profile" replace={true} />
      </div>
    );
  }

  return (
    <MainWrapper>
      <div className="content-container">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          containerId="profileNotification"
        />
        <ToastContainer />

        {/* profile section */}
        <div className="profileContainer">
          <div className="imageContainer">
            {userData === null ? (
              <img
                className="profileImage"
                src={
                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                }
                alt="Profile image"
              />
            ) : (
              <img
                className="profileImage"
                src={
                  userData?.user_profile_pic_url !== null
                    ? userData?.user_profile_pic_url
                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                }
                alt="Profile image"
              />
            )}
          </div>
          <div className="contentContainer">
            <h2 className="profileName">{userData?.user_name}</h2>
            <div className="emailContainer">
              <EmailOutlinedIcon fontSize="small" />
              <h6 className="emailText">{userData?.user_email}</h6>
            </div>
          </div>
          <div className="editContainer">
            <IconButton aria-label="edit" onClick={() => setModalShow(true)}>
              <EditIcon />
            </IconButton>
          </div>
        </div>

        {/* nav items */}
        <div
          className="profileCouponContainer"
          onClick={() => navigate(`/coupons-usage/${userData?.user_id}`)}
        >
          <div className="profileMenuParent">
            <LocalOfferOutlinedIcon fontSize="small" />
            <h4 className="profileMenuText">Coupon Usage History</h4>
          </div>
          <NavigateNextOutlinedIcon />
        </div>
        <div className="profileCouponContainer">
          <div className="profileMenuParent">
            <ChatBubbleOutlineOutlinedIcon fontSize="small" />
            <h4 className="profileMenuText">Contact Us</h4>
          </div>
          <NavigateNextOutlinedIcon />
        </div>
        <div className="profileCouponContainer">
          <div className="profileMenuParent">
            <ArticleOutlinedIcon fontSize="small" />
            <h4 className="profileMenuText">Terms and Privacy</h4>
          </div>
          <NavigateNextOutlinedIcon />
        </div>

        {/* logout container */}
        <div className="logoutContainer" onClick={userLogout}>
          <h4 className="profileMenuText">Logout</h4>
          <Logout fontSize="small" />
        </div>

        {/* update user modal */}
        <UpdateUser
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={userData}
          userUpdated={userUpdated}
        />
      </div>
    </MainWrapper>
  );
};
