import React, { useState } from "react";
import "./ProductCategory.css";
import MainWrapper from "../../components/layout/layout";
import { Navigate, useLoaderData } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Spinner from "react-bootstrap/Spinner";
import {
  addProductCategory,
  updateProductCategory,
} from "../../services/productCategory";
import { CategoryItems } from "../../components/categoryItems/CategoryItems";

export const ProductCategory = () => {
  const loader = JSON.parse(useLoaderData());
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [categoryAddedFlag, setCategoryAddedFlag] = useState(false);
  const [updateId, setUpdateId] = useState(null);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // category update
    if (updateId !== null) {
      const res = await updateProductCategory(updateId, data.category);

      if (res === null) {
        setLoading(false);
        alert("something went wrong, please try later");
        return;
      }

      setData({});
      setCategoryAddedFlag(!categoryAddedFlag);
      setLoading(false);
      setUpdateId(null);
      return;
    }

    // category add
    const res = await addProductCategory(data.category);

    if (res === null) {
      setLoading(false);
      alert("something went wrong, please try later");
      return;
    }

    setCategoryAddedFlag(!categoryAddedFlag);
    setData({});
    setLoading(false);
    setUpdateId(null);
    return;
  };

  const handleUpdate = (data) => {
    setData({});
    setUpdateId(data.product_category_id);
    setData({ category: data.product_category_name });
  };

  if (loader === null) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/login/productcategory" replace={true} />
      </div>
    );
  }

  if (loader.email !== process.env.REACT_APP_ADMIN_EMAIL) {
    return (
      <div className="loaderParent">
        <Spinner animation="border" variant="primary" />
        <Navigate to="/barcode/login" replace={true} />
      </div>
    );
  }

  return (
    <MainWrapper>
      <div className="content-container">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography sx={{ mx: 5 }} component="h1" variant="h5">
              <strong>Add Product Category</strong>
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ m: 5 }}>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="name"
                    name="category"
                    type="text"
                    label="Category Name"
                    variant="outlined"
                    required
                    fullWidth
                    value={data.category || ""}
                    onChange={handleChange}
                  />
                  <Button
                    className="customBtn"
                    sx={{ mt: 3 }}
                    size="large"
                    type="submit"
                    fullWidth
                    variant="contained"
                  >
                    {!loading ? `${updateId ? "Update" : "Add"}` : "Loading..."}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <CategoryItems
              categoryAddedFlag={categoryAddedFlag}
              handleUpdate={handleUpdate}
            />
          </Grid>
        </Grid>
      </div>
    </MainWrapper>
  );
};
